var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"none"},[_c('div',{staticClass:"sidebar"},[_c('nav',{attrs:{"id":"sidemenu"}},[_c('v-tooltip',{attrs:{"right":"","color":"orange darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"navbtn",staticStyle:{"width":"70px","height":"70px","margin":"0"},attrs:{"fab":"","elevation":"0","tile":"","color":"amber darken-2"},on:{"click":_vm.home}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-home")])],1)],1)]}}])},[_c('span',[_vm._v("Accueil")])]),_c('div',{attrs:{"id":"btnmenu"}},[_c('v-tooltip',{attrs:{"right":"","color":"orange darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"navbtn",staticStyle:{"width":"70px","height":"70px","margin":"0"},attrs:{"fab":"","elevation":"0","tile":"","color":"amber darken-2"},on:{"click":_vm.fullsizemap}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-map")])],1)],1)]}}])},[_c('span',[_vm._v("Carte")])]),_c('v-tooltip',{attrs:{"right":"","color":"orange darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"navbtn",staticStyle:{"width":"70px","height":"70px","margin":"0"},attrs:{"fab":"","elevation":"0","tile":"","color":"amber darken-2"},on:{"click":_vm.fullsizetable}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-table")])],1)],1)]}}])},[_c('span',[_vm._v("Tableau")])]),_c('v-tooltip',{attrs:{"right":"","color":"orange darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"navbtn",staticStyle:{"width":"70px","height":"70px","margin":"0"},attrs:{"fab":"","elevation":"0","tile":"","color":"amber darken-2"},on:{"click":_vm.showElementFilter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-checkbox-marked")])],1)],1)]}}])},[_c('p',[_vm._v("Communes et catégories ")]),_c('p',[_vm._v(" Toponymes: "+_vm._s(this.count))])]),_c('v-tooltip',{attrs:{"right":"","color":"orange darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"navbtn",staticStyle:{"width":"70px","height":"70px","margin":"0"},attrs:{"fab":"","elevation":"0","tile":"","color":"amber darken-2"},on:{"click":_vm.showAddForm}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-map-marker")])],1)],1)]}}])},[_c('span',[_vm._v("Créer un point")])]),_c('v-tooltip',{attrs:{"right":"","color":"orange darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"navbtn",staticStyle:{"width":"70px","height":"70px","margin":"0"},attrs:{"fab":"","elevation":"0","tile":"","color":"amber darken-2"},on:{"click":_vm.exportSelection}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file-export")])],1)],1)]}}])},[_c('span',[_vm._v("Exporter la selection")])])],1),_c('div',{staticClass:"btnlogout"},[_c('v-tooltip',{attrs:{"right":"","color":"orange darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"navbtn",staticStyle:{"width":"70px","height":"70px","margin":"0"},attrs:{"fab":"","elevation":"0","tile":"","color":"amber darken-2"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-logout")])],1)],1)]}}])},[_c('span',[_vm._v("Se déconnecter")])])],1)],1)]),(_vm.isElementFilter)?_c('ElementFilter'):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogExport),callback:function ($$v) {_vm.dialogExport=$$v},expression:"dialogExport"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Format d'export")]),_c('v-card-text',[_vm._v("Choisissez le format de téléchargement")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"amber darken-2","text":""},on:{"click":function($event){return _vm.downloadFile('csv')}}},[_vm._v(" CSV ")]),_c('v-btn',{attrs:{"color":"amber darken-2","text":""},on:{"click":function($event){return _vm.downloadFile('json')}}},[_vm._v(" JSON ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }